<template>
  <div class="AdUnit">
    <component
      :is="provider"
      :index="index"
      :ad-slot="adSlot"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// TODO: Add support for Advertserve back in
// TODO: Remove the three files from the /Ads root
const Advertserve = () => import('@/components/Ads/Advertserve/Ad.vue');
const Static = () => import('@/components/Ads/Static/Ad.vue');

export default {
  props: {
    adSlot: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
  },

  computed: {
    ...mapState('advertisements', {
      ad_provider: (state) => state.provider,
    }),
    provider() {
      if (this.ad_provider === 'advertserve') {
        return Advertserve;
      }

      return Static;
    },
  },
};
</script>
